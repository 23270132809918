.not-front{
    #main-content{
        padding-bottom: 100px;
    }

    main{
        @extend %text-basis-rte;
    }
    .hat{
        margin-bottom: 30px;
    }
    .region-nav-tools{
        padding-top: 5px;
        padding-bottom: 5px;
        @extend %container;
        display: flex;
        align-items: center;     
        margin-bottom: 20px;
        margin-top: 20px;

        .block-container-breadcrumb{
            margin-right: auto;
        }
    }

    @include RWD(tablet_only){
        #logo{
            top: 10px;
            left: 30px;
            h1, a{
                span:nth-child(1){
                    font-size: 1.5rem;
                    line-height: 1.8rem;
                    margin-bottom: 10px;
                }
                span:nth-child(2){
                    font-size: 6rem;
                    line-height: 3rem;
                }
            }
        }
        header .header-right .nav-alt{
            margin-bottom: 10px;
            .social-links a{
                text-indent: -9999px;
            }
        }
    }

    // Publié le d'une actu
    .actu-published{
        text-align: right;
        font-style: italic;
        color: $grey-color-dark;
        font-size: 1.2rem;
        margin-bottom: 20px;
    }



    form.filters {
        display: flex !important;
        flex-wrap: wrap;
        padding: 50px 100px 30px;
        margin: 0 -30px 45px;
        background-color: $core-color;
        box-shadow: 5px 8px 10px 0 rgba(0, 0, 0, 0.1);

        @include RWD(mobile){
            padding: 50px 30px 30px;
        }

        .customSelectContain{
            @extend %atom-customSelect--default;
            width: 100%;
            height: auto;
            .customSelectInner{
                font-size: 1.6rem;
                font-weight: 500;
                line-height: 6rem;
                color: $grey-color-light;
            }
        }
        input, select, .customSelectContain .customSelect, .form-field, .selectize-control .selectize-input{
            font-family: $site-font;
            width: 100%;
            height: 60px;
            margin-bottom: 20px; 
            padding: 0 20px;
            font-size: 1.6rem;
            font-weight: 500;
            line-height: 6rem;
            border: none;
            color: $grey-color-light;
            background-color :#fff;
            border-radius: 0;
            box-shadow: none;
        }
        .selectize-control{
            width: 100%;
            margin-bottom: 20px;
            .selectize-input{
                margin-bottom: 0;
            }
        }
        .selectize-dropdown{
            border: none;
            box-shadow: none;
            border-top: 1px solid #f5f5f5;
        }

        .form-field{
            padding: 0;
        }

        .pastEvent a{
            text-decoration: none;
            color: $grey-color-dark;
            text-transform: uppercase;
            font-size: 1.6rem;
            font-weight: 600;

            &:after{
                content: '';
                display: block;
                position: relative;
                top: -2px;
                height: 2px;
                width: 0;
                background-color: $grey-color;
            }

            &:hover, &:focus{
                &:after{
                    @extend %animWidthUnderline;
                    width: 100%;
                }
            }
            
        }


        button[type="submit"]{
            @extend %atom-btn-int--inverted;
            margin-left: auto;
        }

    }

    form.filters + .view-header,
    .formSearchInfos{
        font-size: 1.6rem;
        font-style: italic;
        color: $grey-color-dark;
        margin: 0 -30px 30px;
    }

    .btn-offre{
        @extend %atom-btn-int--inverted;
    }
    

    .view-list>ul.list-list, ul.view-list.list-list{
        margin: 0 -30px 30px;
        box-shadow: 5px 8px 10px 0 rgba(0, 0, 0, 0.1);
        
        >li a{
            display: block;
            padding: 15px 120px 15px 30px;
            background-color: #fff;
            border-bottom: 10px solid $core-color; 
            text-decoration: none;
            position: relative;               

            &:after{
                content: '';
                position: absolute;
                height: 17px;
                width: 33px;
                top: 50%;
                right: 45px;
                transform: translateY(-50%) rotate(-90deg);
                background-image: url(svg-bg-uri($svg-arrow-line, $grey-color-light));
                background-position: 0 0;
                background-repeat: no-repeat;
                background-size: cover;
                transition: all 0.35s ease;
            }

            .grid-item-visu{
                display: none;
            }

            .list-item-title,
            .grid-item-title{
                margin-bottom: 15px;
                display: inline-block;
                font-size: 2.2rem;
                font-weight: 600;
                color: $grey-color-dark;
                &:after{
                    content: '';
                    display: block;
                    position: relative;
                    top: 2px;
                    height: 2px;
                    width: 0;
                    background-color: $grey-color-dark;
                }

                
            }
            .list-item-description,
            .grid-item-text{
                font-size: 1.6rem;
                line-height: 2.2rem;
                color: $grey-color-light;
            }

            &:hover, &:focus{
                background-color: #f5f5f5;
                &:after{
                    right: 35px;
                }
                .list-item-title:after{
                    @extend %animWidthUnderline;
                    width: 100%;
                }
            }
            
        }
        
    }

    #fiche_annuaire{

        .contact-block{
            @extend %molecule-rte-contact;

            width: 100%;
            min-height: 325px;
            @include RWD(tablet){
                display: block;
            }

            .gmap{
                width: 330px;
                flex-shrink: 0;
                @include RWD(tablet){
                    width: 100%;
                    height: 250px;
                }
                div[id^="ezgml-map"]{
                    width: 100% !important;
                    height: 100% !important;
                }
            }
            .infos{
                flex: 1;
                display: flex;
                padding: 40px;
                @include RWD(mobile){
                    display: block;
                }
            }

            .content-1{
                // padding: 29px 0 0 40px;
                padding-right: 20px;
            }
            .content-2{
                padding: 0 20px;
            }
            .content-3{
                padding-left: 20px;
            }
            .content-2,
            .content-3{
                // padding: 66px 0 0 40px;
            }
            .content-{
                &1, &2, &3{
                    flex: 1;
                    @include RWD(mobile){
                        padding: 0;
                    }
                    & > :last-child{
                        @include RWD(desktop){
                            margin-bottom: 0;                            
                        }
                        @include RWD(tablet_only){
                            margin-bottom: 0;
                        }
                    }
                }
            }

            .contact-name{
                padding-left: 40px;
                margin-left: -40px;
                margin-bottom: 19px;
            }

            .contact-site{
                a{
                    text-decoration: none;
                    color: $grey-color-light;
                    &:hover, &:focus{
                        text-decoration: underline;
                    }
                }
            }

            .contact-social{
                a{
                    text-indent: -9999px;
                    display: inline-block;
                    width: 30px;
                    height: 30px;
                    background-size: 30px;
                    background-repeat: no-repeat;
                    background-position: 50%;
                }
                .icon-facebook{
                    background-image: url(svg-bg-uri($svg-facebook, $second-color));
                }
                .icon-twitter{
                    background-image: url(svg-bg-uri($svg-twitter, $second-color));
                }
            }
        }

        .infos-complementaires{
            font-size: 1.6rem;
            line-height: 2.2rem;

            h3{
                @extend %atom-h2-rte;
            }
        }
    }


        /*------------------------------------*\
                    CARTOGRAPHIE
        \*------------------------------------*/
        #cartographie_map{
            position: relative;
            background-color:#F0F0F0;
            margin-left: -30px;
            margin-right: -30px;

            @include RWD(mobile){
                margin-left: -20px;
                margin-right: -20px;
            }
             
            height: 590px;

            .map{
                height: 590px;
                width:100%;
            }

            .gmap-actions{
                position: absolute;
                left: 10px;
                top: 10px;
                z-index: 1;

                button{
                    display:block;
                    width:48px;
                    height:48px;
                    line-height:48px;
                    background-color:$second-color;
                    font-size: 20px;
                    .picto{
                        width: 48px;
                        height: 48px;
                        display: inline-block;
                        background-repeat: no-repeat;
                        background-size: 20px 20px;
                        background-position: 50%;
                    }
                }
                
                #gmapMenuBtn{
                    background-color: $core-color;
                    .picto{
                        background-image: url(svg-bg-uri($svg-map-menu, $grey-color-dark));
                    }
                }
                #gmapMenu, #gmapSearch{
                    position: absolute;
                    top: 58px;
                    left: 58px;
                    background-color:#fff;
                    width: 320px;
                    padding-top: 10px;

                    .title{
                        padding: 0 20px;
                        font-family: $site-font;
                        height: 40px;
                        line-height: 40px;
                    }

                }
                #gmapMenu{
                    font-family: $site-font;
                    padding-bottom: 20px;
                    overflow: hidden; 
                    
                    ul{
                        &.lvl1{
                            max-height: 400px;
                            overflow: auto;
                        }

                        > li{
                            height:37px; 
                            padding:0 20px;
                            background-color: #fff;
                            .catInputWrapper{
                                border-bottom: 1px solid #ebedf0;
                                position: relative;

                                input{
                                    margin-right: 5px;
                                    position: absolute;
                                    top: 7px;
                                }
                                label{
                                    display: block;
                                    width: 100%;
                                    height:37px; 
                                    padding-left: 20px;
                                    line-height: 37px;
                                    font-size: 12px;
                                    text-transform: uppercase;
                                    &:first-letter{
                                        font-size: 14px;
                                    }
                                }
                                .icon-chevron{
                                    position: absolute;
                                    top: 0;
                                    right: 0;
                                    height: 37px;
                                    width: 35px;
                                    line-height: 37px;
                                    display: block;
                                    background-color: transparent;
                                }

                            }

                            &:last-child{
                                .catInputWrapper{
                                    border: none;
                                }
                            }

                            .subLevel{
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                transform: translateX(105%);
                                height: 100%;
                                background-color: #fff;
                                padding-top: 10px;
                                transition: transform 0.5s ease-in-out;

                                &.active{
                                    transform: translateX(0);
                                }

                                .icon-chevron-invert{
                                    transform: rotate(90deg);
                                    float:left;
                                    height: 40px;
                                    width: 40px;
                                }

                                ul.lvl2{
                                    clear:both;
                                }
                            }

                            &:hover{
                                background-color:#f5f5f5;
                            }
                        }
                    }
                }
                #gmapSearchBtn{
                    position: absolute;
                    top: 0;
                    left: 58px;
                    background-color: $core-color;
                    .picto{
                        background-image: url(svg-bg-uri($svg-map-search, $grey-color-dark));
                    }
                }
                #gmapSearch{
                    form{
                        width: 100%;
                        padding: 0 20px;
                        overflow: hidden;
                        .form-input{
                            position:relative;
                            input{
                                font-size: 1.5rem;
                                font-family: $main-font;
                                height: 32px;
                                line-height: 32px;
                                padding:0 40px 0 10px;
                                border: 1px solid #ccc;
                                background-color: #fff;
                                width: 100%;
                            }
                            label{
                                position:absolute;
                                top: 1px;
                                right: 1px;
                                width: 40px;
                                height: 32px;
                                background: transparent url(svg-bg-uri($svg-map-search, $grey-color-dark)) no-repeat 50%;
                                background-size: 20px;
                            }
                        }
                        button[type="submit"]{
                            width: auto;
                            margin: 15px 0 25px;
                            float: right;
                        } 
                    }
                }

                #gmapFilters{
                    display:flex;
                    position: absolute;
                    top: 0;
                    left: 116px;
                    width: auto;
                    background-color:#fff;
                    height: 48px;

                    #gmapFiltersRefresh{
                        .picto{
                            background-image: url(svg-bg-uri($svg-map-plus, $grey-color-dark));
                            transform: rotate(45deg) translate3d(4px, 4px, 0);
                            width: 24px;
                            height: 24px;
                        }
                    }
                    #gmapFiltersText{
                        font-family: $site-font;
                        white-space: nowrap;
                        padding: 12px 15px 12px 0;
                        line-height: 24px;

                        span{
                            font-family: $main-font;

                        }
                    }
                }

                #gmapZoom{
                    position:absolute; 
                    top: 58px;
                    left: 0;
                    #gmapZoomInBtn{
                        margin-bottom: 4px;
                        .picto{
                            background-image: url(svg-bg-uri($svg-map-plus, $grey-color-dark));
                        }
                    }
                    #gmapZoomOutBtn{
                        .picto{
                            background-image: url(svg-bg-uri($svg-map-minus, $grey-color-dark));
                        }
                    }
                }
                // #gmapFullScreenBtn{
                //     position: absolute;
                //     top: 168px;
                //     left: 0;
                //     .picto{
                //         background-image: url(svg-bg-uri($svg-map-fullscreen, $grey-color-dark));
                //     }
                // }
            }

            .gmap-infowindow{
                width:320px;

                .close-infowindow{
                    height: 20px;
                    width: 20px;
                    background-image: url(svg-bg-uri($svg-map-plus, $grey-color-light));
                    transform: rotate(45deg);
                    display: block;
                    position: absolute;
                    top: 20px;
                    right: 20px;
                }
                .viewport{
                    .content{
                        font-family: $site-font;
                        padding: 30px;
                        font-size: 1.6rem;
                        line-height: 2.2rem;
                        
                        .title{
                            background-color: #9bd9b2;
                            min-height: 18px;
                            line-height: 18px;
                            font-size: 2.2rem;
                            font-weight: 600;
                            color: $grey-color-dark;
                            display: inline-block;
                            padding-right: 3px;
                            padding-left: 30px;
                            margin-left: -30px;
                        }
                        .categorie{
                            font-size: 1.6rem;
                            margin-bottom: 20px;
                        }
                        .description{
                            font-size: 1.6rem;
                        }
                        .address{
                            display: block;
                            font-weight: 600;
                            text-transform: uppercase;
                            color: $grey-color-dark;

                            >div{
                                font-weight: 400;
                                font-size: 1.6rem;
                                color: $grey-color-light;
                            }
                        }
                        .generic{
                            display: block;
                            font-weight: 400;
                            font-size: 1.6rem;
                            color: $grey-color-light;
                            margin-bottom: 20px;
                            &[data-test-visible=" "]{
                                display: none;
                            }
                            strong{
                                font-weight: 600;
                                text-transform: uppercase;
                                color: $grey-color-dark;
                            }
                        }
                    }
                    .actions{
                        background-color:#f5f5f5;
                        display: flex;
                        li{
                            display: block;
                            width: 100%;
                            background-color: $second-color;
                            
                            a{
                                @extend %atom-btn-int--inverted;
                                display: block;
                                padding: 0 30px;
                                position: relative;
                                height: 50px;
                                line-height: 50px;
                                
                                .btn-text{
                                    display: inline-block;   
                                    &:after{
                                        top: -15px;
                                    }
                                }

                            }
                        }
                    }
                }
            }

            &.singlePoi{
                .gmap-actions #gmapZoom{
                    top: 0;
                }

                .loader{
                    &>div{
                        display: none;
                    }
                }
            }

        }
        .loader{
            margin: 0;
            padding: 20px 0;
            background-color:rgba(240, 240, 240, 0.6);
            font-size: 2rem;
            font-family: $site-font;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            span{
                margin-right:15px;
            }
        }

        // .poi-wrapper{
        //     position: relative;

        //     .poi-list-infos{
        //         display:block;
        //         width: 100%;
        //         min-height: 50px;
        //         line-height: 50px;
        //         padding: 20px 0 30px;
        //     }

        //     .poi-loader{
        //         top: 100px;
        //         height: calc(100% - 225px);
        //     }

        //     .poi-list{
        //         display: flex;
        //         flex-flow: row wrap;
        //         min-height:200px;
                
        //         li:before{
        //             content: none;
        //         }

        //         .poi-card{
        //             display: block;
        //             width: calc((100% - 40px) / 3);
        //             margin-bottom: 20px;
        //             margin-right: 20px;
        //             background-color: #f0f0f0;
        //             &:before{
        //                 content: none;
        //             }
                    

        //             a{
        //                 width: 100%;
        //                 height: 100%;
        //                 display: block;
        //             }

        //             .poi-visu{
        //                 padding-bottom: 73%;
        //                 background-size: cover;
        //                 position: relative;
        //                 .poi-cat{
        //                     position: absolute;
        //                     top: 10px;
        //                     left: 10px;
        //                     height: 35px;
        //                     line-height: 35px;
        //                     padding: 0 20px;
        //                     background-color: #fff;
        //                     color: #3d3d3d;
        //                     font-size: 2rem;
        //                     font-family: $site-font;
        //                 }
        //             }

        //             .poi-content{
        //                 padding:40px 25px;
        //                 .poi-title{
        //                     padding-top: 14px;
        //                     margin-bottom:8px;
        //                     border-top: 1px solid #cccccc;
        //                     color: #3d3d3d;
        //                     font-size: 2rem;
        //                     font-family: $site-font;
        //                     letter-spacing: 0.02em;
        //                     line-height: 25px;
        //                 }
        //                 .poi-desc{
        //                     color:#636363;
        //                     font-size: 1.6rem;
        //                     font-family: $main-font;
        //                     letter-spacing: 0.04em;
        //                     line-height: 24px;
        //                 }
        //             }

        //             @include RWD(desktop){
        //                 &:nth-child(3n){
        //                     margin-right:0;
        //                 }
        //             }
        //             @include RWD(tablet){
        //                 width: calc((100% - 20px) / 2); 
                        
        //                 &:nth-child(2n){
        //                     margin-right:0;
        //                 }
        //             }

        //         }
        //     }

        //     .poi-list-pagination{

        //         width: 100%;
        //         display: flex;
        //         justify-content: space-between;
        //         margin-top: 80px;

        //         >.btn{
        //             height: 45px;
        //         }
        //         .prev{
        //             padding: 0.6em 3.3333333em 0.5em 1.666666em;

        //             &:before{
        //                 left: auto;
        //                 right: 1.666666em;
        //             }
        //         }

        //         .pages li{
        //             margin: 0 5px;
        //             display: inline-block;

        //             button{
        //                 display: block;
        //                 height: 45px;
        //                 width: 65px;
        //                 text-align: center;
        //                 line-height: 45px;
        //                 background-color: #f0f0f0;
        //                 font-family: $site-font;
        //                 font-size: 1.8rem;

        //                 &:hover{
        //                     background-color: $core-color;
        //                     color: #fff;
        //                 }
        //             }
        //             &.active button{
        //                 background-color: $core-color;
        //                 color:#fff;
        //             }

        //         }

        //     }
        // }

}