%text-basis-rte{    
    font-family: $site-font;
    //font-size: 16px;
    font-size: 1.6rem;
    color: $grey-color-light;
    letter-spacing: 0.04em;
    font-weight: 400;
    line-height: 2.2rem;
}

%text-caption-rte{
    color: #000000;
    //font-size: 14px;
    font-size: 1.4rem;
    font-weight: 300;
    font-style: italic;
}

%text-teaser-description{
    font-weight: 400;
    //font-size: 15px;
    font-size: 1.5rem;
    line-height: normal;
}