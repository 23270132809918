.not-front .grid-trombinoscope{
    @extend %molecule-grid-trombinoscope;

    
    .grid-item{
        @extend %atom-vignette-trombinoscope;
        margin-top: 40px;

        @include RWD(tablet){
            margin-top: 30px;
        }

        &:nth-child(1), &:nth-child(2){
            @include RWD(desktop){
                margin-top: 0;                
            } 
        }
    }
}