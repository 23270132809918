// Yosemite
.class_identifier_sitemap{
    &.not-front .rte{
        h2{
            font-size: 2.5rem;
            &:before,
            &:after{
                height:0;
                margin: 0;
                padding: 0;
                height:10px;
            }
            &:before{
            }
        }
        h3{
            font-size: 2rem;
            margin-bottom: 10px;
            padding-top: 5px
        }
        h4{
            font-size: 1.8rem;
        }

        a{
            color: inherit;
            text-decoration: none;
    
            &:hover, &:focus{
                text-decoration: underline;
            }
        }
    }
}

// Drupal
.page-sitemap #site-map{
    @extend %organism-sitemap-drupal;
}