%molecule-media-download{
    position: relative;
    
    .media-top-line{
        display: flex;
        flex-wrap: wrap;

        @include RWD(mobile){
            display: block;
        }
    }
    .media-text{
        flex: 1;
    }
    
    .media-visu{
        width: 125px;
        height: 130px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 20px;

        img{
            max-height: 100%;
            max-width: 100%;
        }
    }
    .media-ratio{
        width: auto;
        text-align: right;

        @include RWD(mobile){
            text-align: center;
            margin-top: 20px;
        }
        
        .media-weight{
            text-align: right;
            margin-bottom: 10px;
            @include RWD(mobile){
                text-align: center;
            }
        }
        .media-download{
            @extend %atom-btn-int--inverted;
            display: inline-flex;
        }
    }
    .media-informations{
        display: flex;
        flex-direction: column;
    }
}