%atom-link-rte{
    text-decoration: underline;
    color: $grey-color-dark;
    font-weight: 600;
        
    &:hover, &:focus{
        color: $grey-color-light;
        text-decoration: underline;
    }

    &.external-link{
        &:after{
            content: '*';
            vertical-align: super;
            font-size: smaller;
        }
    }
}
