$path: '../fonts';

// Proxima Nova

    // Regular
    @font-face {
        font-family: 'ProximaNova';
        src: url('#{$path}/ProximaNova-Regular.woff') format('woff');
        font-weight: 400;
        font-style: normal;
    }

    // SemiBold
    @font-face {
        font-family: 'ProximaNova';
        src: url('#{$path}/ProximaNova-Semibold.woff') format('woff');
        font-weight: 500;
        font-style: normal;
    }

    // Bold
    @font-face {
        font-family: 'ProximaNova';
        src: url('#{$path}/ProximaNova-Bold.woff') format('woff');
        font-weight: 600;
        font-style: normal;
    }
