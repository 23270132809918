%molecule-audio-player{
    display: flex;
    width: 655px;
    max-width: 100%;
    background-color: #FFFFFF;
    border: 1px solid #797979;
    margin: 0 auto;
    @include RWD(mobile){
        display: block;
        padding: 20px;
    }


    audio{
        display: none;
    }

    // Visuel
    .audio-visu{
        img{
            height: 140px;
            width: 140px;
        }
        @include RWD(mobile){
            margin-bottom: 20px;
        }
    }

    // Partie droite infos
    .audio-infos{
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        padding: 20px;

        @include RWD(mobile){
            padding: 0;
        }
    }

    .audio-title{
        font-weight: 600;
        font-size: 24px;
        font-size: 2.4rem;
        color: #1c1c1c;
        margin-bottom: 10px;
    }
    .audio-artist{
        font-weight: 400;
        font-size: 16px;
        font-size: 1.6rem;
        color: #1C1C1C;
        margin-bottom: 5px;

        @include RWD(mobile){
            margin-bottom: 20px;
        }
    }

    // Lecteur audio
    .audio-player{
        display: flex;
        width: 360px;
        max-width: 100%;

        // Bouton playpause
        .player-control{
            cursor: pointer;
            margin-right: 20px;
            height: 30px;
            width: 25px;
            transition: all, 0.5s;
            .flexbox{
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .btn-play{
                @extend %icon-play;
            }
            .btn-pause{
                @extend %icon-pause;
                margin: 0 auto;
                display: none;
            }
            .hidden{
                display: none;
            }

            &.playing{
                .btn-play{
                    display: none;
                }
                .btn-pause{
                    display: flex;
                }
            }
            &:hover, &:focus{
                opacity: 0.5;
            }
        }

        // Vue bar + temps
        .player-view{
            flex: 1;
            .player-timing{
                display: flex;  
                justify-content: space-between;
                margin-bottom: 5px;

                .player-time-current{
                    font-size: 11px;
                    font-size: 1.1rem;
                    color: #1c1c1c;
                }
                .player-time-total{
                    font-size: 11px;
                    font-size: 1.1rem;
                    color: $core-color-access;
                }
            }
            .player-progress-bar{
                height: 5px;
                width: 100%;
                background-color: #d6dde2;
                position: relative;
                border-radius: 5px;
                overflow: hidden;

                input[type="range"]{
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    margin: 0;
                    transform: translateY(-50%);
                    opacity: 0;
                    cursor: pointer;
                    z-index: 10;
                }
                .player-running-bar{
                    background-color: $core-color-access;
                    position: absolute;
                    top: 0;
                    left: 0; 
                    height: 100%;
                    width: 0%; 
                }
            }
        }
    }
}