%molecule-inlines-rte{
    @extend %text-basis-rte;

    strong{
        font-weight: 600;
    }
    em{
        font-style: italic;
    }
    sup{
        vertical-align: super;
        font-size: smaller;
        line-height: normal; 
    }
    sub{
        vertical-align: sub;
        font-size: smaller;
        line-height: normal;
    }
    a{
        @extend %atom-link-rte;
    }
}