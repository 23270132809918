%atom-slider-arrow--cms{
    transition: all, 0.5s;
    cursor: pointer;
    z-index: 10;
    position: absolute;

    .flexbox{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    svg{
        height: 30px; 
        width: auto;
        opacity: 1;
    }

    &.disabled{
        pointer-events: none;
        svg{
            opacity: 0;
        }
        @include RWD(mobile){
            display: none;
        }
        .owl-pagination{
            display: none !important;
        }
    }

    &--grey{
        @extend %atom-slider-arrow--cms;
        background-color: #202020;
        path{
            fill: #FFFFFF;
            transition: all, 0.5s;
        }
        &:hover, &:focus{
            background-color: $core-color;
        }
    }
}

%atom-slider-arrow-paged--cms{
    width: 50px;
    height: 50px;
    .owl-pagination{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        &:after{
            height: 100%;
            width: 1px;
            content: '';
            display: block;
            transform: rotate(45deg);
            z-index: 2;
            position: absolute;
            top: 0;
            left: 50%;
        }

        .owl-current-page{
            position: absolute;
            top: 10px;
            left: 10px;
            line-height: 1;
        }
        .owl-total-page{
            position: absolute;
            bottom: 10px;
            right: 10px;
            line-height: 1;
        }
    }

    &.owl-prev{
        .owl-pagination{
            transform: translateX(100%);
        }
    }
    &.owl-next{
        .owl-pagination{
            transform: translateX(-100%);
        }
    }

    &:hover, &:focus{
        .owl-pagination{
            display: block;
            opacity: 1;
        }
    }

    &--grey{
        @extend %atom-slider-arrow-paged--cms;
        .owl-pagination{
            background-color: #2E2E2E;
            color: #FFFFFF;

            &:after{
                background-color: #FFFFFF;
            }
        }
    }
}