%molecule-grid-basic{
    clear: both;
    @include RWD(desktop){
        @include grid-distribution(3, 20, 'grid-item');                
    }
    @include RWD(tablet){
        @include grid-distribution(2, 10, 'grid-item');                
    }
    @include RWD(mobile){
        @include grid-distribution(1, 0, 'grid-item');                
    }

    /* Vignettes */
    .grid-item{
        margin-bottom: 30px;
        display: flex;

        & > a, & > div{
            flex: 1;
            @extend %atom-vignette;
        }
        a{            
            &:hover, &:focus{
                @extend %animation-vignette;
            } 
        }
    }
}