%organism-rte{
    // Clear paragraphs drupal
    .field-item{
        clear: both;
    }

    // Inlines
    @extend %molecule-inlines-rte;

    /* Headings */
    h2{
        @extend %atom-h2-rte;
        // clear: both;
        margin-bottom: $margin-h2; 

        @include RWD(mobile){
            margin-bottom: $margin-h2-mobile;            
        }
    }
    h3{
        @extend %atom-h3-rte; 
        // clear: both;
        margin-bottom: $margin-h3;

        @include RWD(mobile){
            margin-bottom: $margin-h3-mobile;            
        }
    }
    h4{
        @extend %atom-h4-rte;
        // clear: both;
        margin-bottom: $margin-h4;

        @include RWD(mobile){
            margin-bottom: $margin-h4-mobile;            
        }
    } 
    h5{
        @extend %atom-h5-rte;
        // clear: both;
        margin-bottom: $margin-h5;

        @include RWD(mobile){
            margin-bottom: $margin-h5-mobile;            
        }
    }

    /* Inlines */
    p{
        margin-bottom: $margin-p;
        font-size: 1.8rem;
        line-height: 2.8rem;
        }

    /* Images */
    .type-image{
        figure{
            @extend %atom-image-general;
        }
        &.image-xs figure{
            width: $image-xs;
        }
        &.image-sm figure{
            width: $image-sm;
        }
        &.image-md figure{
            width: $image-md;
        }
        &.image-original figure{
            @extend %atom-image-original;
        }
        &.image-full figure{
            @extend %atom-image-full;
        }
        &.image-left figure{
            float: left;
            margin-right: 40px;
        }
        &.image-right figure{
            float: right;
            margin-left: 40px;
        }      
    }

    /* Listes */
    ul:not(.unstyled){ 
        @extend %atom-ul-rte;
        overflow: hidden; // fix les conflits avec le float des images https://stackoverflow.com/questions/710158/why-do-my-list-item-bullets-overlap-floating-elements
    }
    ol:not(.unstyled){
        @extend %atom-ol-rte;
        overflow: hidden; // fix les conflits avec le float des images https://stackoverflow.com/questions/710158/why-do-my-list-item-bullets-overlap-floating-elements
    }  

    /* MEDIAS */
    .media{
        clear: both;
        margin-bottom: $margin-item-rte;
    }

    // Quote
    .quote{
        clear: both;
        & > *:last-child{
            margin-bottom: 0;
        }
        margin-bottom: $margin-item-rte;
    }

    // Highlight Content
    .highlight-content{
        clear: both;
        margin-bottom: $margin-item-rte;
    }

    //  Slider Int
    .slider-int{
        clear: both;
        margin-bottom: $margin-item-rte;
    }
    

    // Trombinoscope
    .grid-trombinoscope{
        clear: both;
        margin-bottom: $margin-item-rte;
    }

    // Groupe de liens
    .link-group{
        clear: both;
        margin-bottom: $margin-item-rte;
    }

    // Bloc spé contact
    .contact-block{
        @extend %molecule-rte-contact;
    }

    // Bloc spé crossreading
    .crossreading-block{
        @extend %molecule-rte-crossreading;
    }

    // Bloc spé faq
    .faq-block{
        @extend %molecule-rte-faq;
    }

    // Bloc spé iframe
    .iframe-block{
        @extend %molecule-rte-iframe;
        margin-bottom: 40px;
    }
}