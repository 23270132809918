%molecule-ariane{
    display: flex;
    flex-wrap: wrap;

    li, li>a, li>div{
        font-size: 1.4rem;
        color: #1e1e1e;
        text-decoration: none;
        text-transform: uppercase;
    }
    li a{
        &:hover, &:focus{
            text-decoration: underline;
        }
    }
    li{
        &:after{
            content: '>';
            margin:0 10px;
        }
        &:last-child{
            &:after{
                display: none;
            }
        }
        .page.active{
            //opacity: 0.7;
        }

        &:first-child>a, 
        &:first-child>div{
            color:#878787;
        }
        &:first-child+li>a,
        &:first-child+li>div{
            color: #545454;
        }
        
        &:first-child+li+li>a,
        &:first-child+li+li>div{
            color: #3b3b3b;
        }
        
    }

    @include RWD(mobile){
        display: none;
    }
}