%molecule-form-components{

    .text-info{
        margin-bottom: $margin-p;
    }
    
    input:not([type="radio"]):not([type="checkbox"]):not(.webform-calendar){ 
         @extend %atom-form-input;

         &[type="file"]{
             border: none;
             padding: 0; 
             margin-right: 20px;
         }
         &.webform-calendar{
             width: auto;
         } 
     } 
     label:not(.option){
         @extend %text-form-label;
        //  text-align: right;
        //  padding-top: 14px; 

         .required{
             color: $error;
         }
     }
 
    //  Textarea
    textarea{
        @extend %atom-form-input;
        @extend %text-form-input;
        padding: 15px;
        height: auto; 
        display: block;
        resize: vertical;
    }
    //  SELECTS
     .customSelectContain{
         @extend %atom-customSelect--default;
     }   

     
 
    //  FILES
     .form-managed-file{
         display: flex;
         align-items: center;
         @extend %atom-form-file;

         button{
             height: 35px;
         }
     }
 
    //  Indications sous le field
     .form-infos{
         @extend %text-form-input;
         margin-top: 5px;
         font-size: small;
     }

    //  Grille de notation
     .type-webform-grid{
        & > .form-field{
            overflow: auto;
        }
     } 
    .webform-grid{
        @extend %molecule-table;
        @extend %molecule-form-table;
        width: 100%;
        border-spacing: 0;
        @include RWD(tablet){
            width: auto;
            max-width: 100%;
        }
    }

    .form-submit, [type="submit"]{
        @extend %atom-btn-int--core;
    } 

    .formNextStep .form-submit, 
    .formPreviousStep .form-submit{
        background-color: $core-color;

        &:hover, &:focus{
            background-color: #fff;
        }
    }
}