.not-front .view-agenda {
    h2{
        @extend %atom-h2-rte;
        &.hidden{
            display: none;
        }
    }
    .view-grid{
        @extend %molecule-grid-basic;  
        
        /* Vignettes */
        .grid-item{
            margin-bottom: 30px;
            display: flex;
    
            a{
                flex: 1;
                @extend %atom-vignette;
    
                &:hover, &:focus{
                    @extend %animation-vignette;
                }
            }
    
            @include RWD(desktop){
                &:nth-child(3n+2){transform: translateY(80px)}
            }
    
            @include RWD(tablet_only){
                &:nth-child(2n){transform: translateY(80px)}
            }
    
        }
        & + .pagination{
            @include RWD(desktop){
                transform: translateY(80px);
            }
        }
    }
}