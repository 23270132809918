%atom-hat-default{
    //@extend %molecule-inlines-rte;
    @extend %text-hat;
    text-align: center;
    .container{
        @extend %container;
    }
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
    max-width: 100vw;
    padding: 30px 0;
    margin-bottom: 30px;
    line-height: normal;
}