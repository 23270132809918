%atom-btn-int{ 
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0 50px;
    height: 60px;
    text-decoration: none;
    position: relative;
    cursor: pointer;
    transition: background-color 0.35s ease;
    font-family: $site-font;
    
    
    .btn-text{
        position: relative;
        color: $grey-color-dark;
        font-weight: 600;
        font-size: 1.6rem;
        text-transform: uppercase;
        
        &:after{
            content: '';
            display: block;
            position: relative;
            top: 5px;
            height: 2px;
            width: 0;
            background-color: $grey-color-dark;
        }
    }

    &:hover, &:focus{
        .btn-text:after{
            @extend %animWidthUnderline;
            width: 100%;
        }
    };

    .flexbox{
        width: 100%;
        z-index: 1;
    }
    
    &--core{
        @extend %atom-btn-int;
        background-color: #fff;
        &:hover, &:focus{
            background-color: $core-color;
        };
    }
    &--inverted{
        @extend %atom-btn-int;
        background-color: $second-color;
        &:hover, &:focus{
            background-color: #fff;
        };
    }
    &--disabled{
        @extend %atom-btn-int;
        background-color: #fff;
        &:hover, &:focus{
            background-color: #fff;
        };
    }
}


//~#BUTTONS
.btn-int--core{
    @extend %atom-btn-int--core;
}

.btn-int--inverted{
    @extend %atom-btn-int--inverted;
}

.btn-int--disabled{
    @extend %atom-btn-int--disabled;
}
//#BUTTONS