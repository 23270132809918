%organism-footer{
    display: block;
    background-color: #fff;

    #block_NewsletterSms{
        background-color: $grey-color;
        .wrapper{
            @extend %container;
            display: flex;
            padding-top: 50px;
            padding-bottom: 50px;
            color: #fff;

            .title{
                margin-right: 20px;
                padding: 10px 0;
                text-decoration: none;
                font-size: 1.6rem;
                font-weight: 500;
                text-transform: uppercase;
                strong{
                    font-size: 2.4rem;
                    display: block;
                    font-weight: 600;
                }
            }

            form{
                display: flex;
                flex: 1;
                margin-right: 20px;
                max-width: 790px;

                .form-group{
                    display: block;
                    flex: 1;
                }
                input, button{
                    height: 60px;
                    line-height: 6rem;
                    font-size: 1.6rem;
                    border: none;
                }
                input{
                    flex:1;
                    padding: 0 20px;
                    color: $grey-color-light;
                    margin-right: 10px;
                    display: block;
                    width: 100%;
                }
                button{
                    padding: 0 50px;
                    background-color: $accent-color;
                    font-weight: 600;
                    text-transform: uppercase;
                    font-family: $site-font;
                }
                small{
                    font-size: 1.2rem;
                    margin-top: 10px;
                    display: block;
                }
            }
            .sms-alert-link{
                color: #fff;
                text-align: right;
                margin-right: 0;
                margin-left: auto;
                width: auto;
                padding-right: 72px;
                background-image: url(svg-bg-uri($svg-smartphone, #fff));
                background-size: 52px 60px;
                background-position: 100% 50%;
                background-repeat: no-repeat;
            }
        }

        @include RWD(tablet){
            .wrapper{
                flex-wrap: wrap;

                form{
                    width: 100%;
                    max-width: 100%;
                    margin-bottom: 40px;
                    margin-right: 0;
                    flex: none;
                }
                .sms-alert-link{
                    margin-left: 0;
                    text-align: left;
                }
            }
        }

        @include RWD(mobile){
            .wrapper{

                form{
                    flex-wrap: wrap;
                    .title{
                        width: 100%;
                    }
                    .form-group {
                        flex-wrap: wrap;
                        flex: none;
                        width: 100%;
                        input{
                            width: 100%;
                            flex: none;
                            margin-bottom: 10px;
                        }
                    }
                    button{
                        width: 100%;
                    }
                }
                .sms-alert-link{

                }
            }
        }
    }

    #block_Partners{
        ul{
            @extend %container;
            display: flex;
            height: 110px;
            margin-bottom: 50px;
            justify-content: space-around;
            align-items: center;
            border-bottom: 1px solid #f8f5f4;
            flex-wrap: wrap;
            li a{
                display:inline-block;
                position: relative;

                img{
                    transition: opacity 0.35s ease; 
                    &:not(.hover){
                        opacity:1;
                    }
                    &.hover{
                        position:absolute;
                        opacity:0;
                        top: 0;
                        left: 0;
                    }
                }
                &:hover, &:focus{
                    img{
                        &:not(.hover){
                            opacity:0;
                        }
                        &.hover{
                            opacity:1;
                        }
                    }
                };
            }
        }
        @include RWD(tablet){
            ul{
                margin: 0 20px 50px;
                padding: 30px 0 0;
                width: calc(100% - 40px);
                height: auto;
                li{
                    margin: 0 0 30px;
                    width: 25%;
                    text-align: center;
                }
            }
        }
    }

    .footer-top-links{
        padding-bottom:50px;
        border-bottom: 1px solid $grey-color-light;
        & > ul{
            @extend %container;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            
            font-size: 1.6rem;
            color: $grey-color-light;
            
            .title{
                margin-bottom: 5px;
                color: $grey-color;
                font-weight: 500;
                text-transform: uppercase;
            }

            & > li p{
                line-height: 23px;
            }
            
            ul{
                li{
                    display: block;
                    padding-top: 20px;
                    
                    a{
                        display: inline-block;
                        min-height: 20px;
                        color: $grey-color-light;
                        text-decoration: none;

                        &:before{
                            content: '>';
                            display:inline-block;
                            margin-right: 10px;
                            transition: color 0.35s ease;
                        }
                        &:hover, &:focus{
                            color: $grey-color-dark;
                            &:before{
                                color: $accent-color;
                            }
                        }
                        
                        &.social-facebook,
                        &.social-twitter{
                            @extend %bg-animation;
                            display: block;
                            line-height: 2rem;
                            padding-left: 25px;
                            background-position: 0 48%;
                            background-repeat: no-repeat;
                            &:before{
                                content: none;
                            }
                            &:hover, &:focus{
                                color: $grey-color-dark;
                            };  
                        }
                        
                        &.social-facebook{
                            background-image: url(svg-bg-uri($svg-facebook, $grey-color-light));
                            background-size: 20px;
                            &:hover, &:focus{
                                background-image: url(svg-bg-uri($svg-facebook, $second-color));
                            };
                        }
                        &.social-twitter{
                            background-image: url(svg-bg-uri($svg-twitter, $grey-color-light));
                            background-size: 19px 16px;
                            &:hover, &:focus{
                                background-image: url(svg-bg-uri($svg-twitter, $second-color));
                            };
                        }
                    }
                }
            }
        }

        @include RWD(tablet){
            & > ul > li{
                width: 33.3333332%;
                &:first-child{
                    width: 100%;
                    text-align: center;
                    margin-bottom: 30px;
                }
            }
        }
        @include RWD(mobile){
            padding: 0;
            margin: 0 20px 10px;

            & > ul > li{
                width: 100%;
                text-align: center;
                flex: none;
                margin-bottom: 35px;
            }

            & > ul ul li a.social-facebook,
            & > ul ul li a.social-twitter{
                display: inline-block;
            }

        }
    }

    .footer-bottom-links{
        @extend %container;
        ul{
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            li a{
                display: inline-block;
                color: $grey-color;
                font-size: 1.4rem;
                text-decoration: none;
                text-transform: uppercase;
                margin-right: 40px;

                &:after{
                    content: '';
                    display: block;
                    position: relative;
                    top: 2px;
                    height: 2px;
                    width: 0;
                    background-color: $grey-color-dark;
                }
                &:hover, &:focus{
                    &:after{
                        @extend %animWidthUnderline;
                        width: 100%;
                    }
                };
            }
        }

        @include RWD(tablet){
            
        }
        @include RWD(mobile){
            padding-bottom: 10px;
            ul{
                flex-wrap: wrap;
                li{
                    width: 100%;
                    text-align: center;
                    line-height: 2.5rem;
                    a {
                        margin: 0;
                    }
                }
            }
        }
    }
}