%organism-header{
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    position: relative;
    z-index: 100;
    @include RWD(desktop){
        overflow: visible;
    }

    #logo{
        padding-top: 50px;
        h1, a{
            display: block;
            text-decoration: none; 
            span:nth-child(1){
                display: block;
                font-size: 2.5rem;
                font-weight: 500;
                line-height: 1.8rem;
                margin-bottom:15px;
                text-transform: uppercase;
                color: $grey-color-mild;
            }
            span:nth-child(2){
                display: block;
                font-size: 10rem;
                font-weight: 600;
                line-height: 7.5rem;
                color: $grey-color-dark;
            }
        }
    }
    .header-right{

        .nav-alt{
            display: flex;
            justify-content: flex-end;
            padding-right: 60px;
            position: relative;
            .social-links{
                display: flex;
                a{
                    @extend %bg-animation;
                    display: block;
                    height: 60px;
                    padding: 20px 20px 20px 45px;
                    font-size: 1.4rem;
                    text-transform: uppercase;
                    color: $grey-color-light;
                    text-decoration: none;
                    line-height: 2rem;
                    background-position: 20px 48%;
                    background-repeat: no-repeat;
                    &:hover, &:focus{
                        color: $grey-color-dark;
                    };
                    
                    &.social-facebook{
                        background-image: url(svg-bg-uri($svg-facebook, $grey-color-light));
                        background-size: 20px;
                        &:hover, &:focus{
                            background-image: url(svg-bg-uri($svg-facebook, $second-color));
                        };
                    }
                    &.social-twitter{
                        background-image: url(svg-bg-uri($svg-twitter, $grey-color-light));
                        background-size: 19px 16px;
                        &:hover, &:focus{
                            background-image: url(svg-bg-uri($svg-twitter, $second-color));
                        };
                    }
                }
            }
            .translate-select{
                position: relative;
                & > a{
                    display: block;
                    height: 60px;
                    padding: 20px 20px 20px 45px;
                    font-size: 1.4rem;
                    text-transform: uppercase;
                    color: #797979;
                    text-decoration: none;
                    line-height: 2rem;
                    background-position: 20px 48%;
                    background-repeat: no-repeat;
                    background-image: url(svg-bg-uri($svg-flag, $grey-color-light));
                    background-size: 20px;
                    &:after{
                        content: '▼';
                        display: inline-block;
                        margin-left: 10px;
                        position: relative;
                        top: -1px;
                        font-size: 1.6rem;
                        transition: all 0.35s ease;
                    }
                }
                ul{
                    position: absolute;
                    left: 45px;
                    top: 40px;
                    display: none;
                    li a{
                        display: inline-block;
                        line-height: 2.5rem;
                        font-family: $site-font;
                        font-size: 1.4rem;
                        color: $grey-color-light;
                        text-transform: uppercase;
                        text-decoration: none;
                        transition: color 0.35s ease;
                        &:after{
                            content: '';
                            display: block;
                            position: relative;
                            top: -5px;
                            height: 2px;
                            width: 0;
                            background-color: $grey-color-dark;
                        }

                        &:hover, &:focus{
                            color: $grey-color-dark;
                            &:after{
                                @extend %animWidthUnderline;
                                width: 100%;
                            }
                        }
                    }
                }
                &.opened{
                    & > a:after{
                        transform: rotateX(180deg);
                    }
                    ul{
                        display: block;
                    }
                }
            }
            .nav-profil{
                padding: 20px 20px 20px 55px;
                background-color: $second-color;
                background-image: url(svg-bg-uri($svg-people, #fff));
                background-position: 30px 48%;
                background-size: 19px;
                background-repeat: no-repeat;
                position: relative;
                height: 60px;
                
                button{
                    text-transform: uppercase;
                    line-height: 2rem;
                    font-family: $site-font;
                    font-size: 1.8rem;
                    font-weight: 600;
                    color: $grey-color;

                    &:after{
                        content: '▼';
                        display: inline-block;
                        margin-left: 10px;
                        position: relative;
                        top: -1px;
                        font-size: 1.6rem;
                        transition: all 0.35s ease;
                    }
                }
                .nav-profil-dropdown{
                    height: 0;
                    overflow: hidden;
                    position: absolute;
                    top:98%;
                    left: 0;
                    width: 218px;
                    background-color: $second-color;
                    z-index: 1;
                    transition: height 0.35s ease;
                    
                    li{
                        padding:0 10px 0 30px;
                        a{
                            display: inline-block;
                            line-height: 3rem;
                            font-family: $site-font;
                            font-size: 1.6rem;
                            font-weight: 600;
                            color: $grey-color;
                            text-transform: uppercase;
                            text-decoration: none;
                            transition: color 0.35s ease;
                            &:after{
                                content: '';
                                display: block;
                                position: relative;
                                top: -5px;
                                height: 2px;
                                width: 0;
                                background-color: #fff;
                            }

                            &:hover, &:focus{
                                color: #fff;
                                &:after{
                                    @extend %animWidthUnderline;
                                    width: 100%;
                                }
                            }
                        }
                    }
                }

                &.opened{
                    button:after{
                        transform: rotateX(180deg);
                    }
                    .nav-profil-dropdown{
                        display: block;
                        height: unset;
                    }
                }
            }
            .search-block{
                position: absolute;
                right: 0;
                top: 0;
                height: 100%;
                width: 60px;
                display: flex;
                transition: all 0.5s ease;

                .toggle-button{
                    @extend %bg-animation;
                    width: 60px;
                    height: 60px;
                    background-color: $grey-color-light;
                    background-image: url(svg-bg-uri($svg-search, #fff));
                    background-repeat: no-repeat;
                    background-position: 50%;
                    background-size: 21px 23px;
                    transition: background 0.35s ease;
                    &:hover, &:focus{
                        background-image: url(svg-bg-uri($svg-search, $second-color));
                    };
                }
                form{
                    width:0;
                    overflow:hidden;
                    display:flex;
                    flex:1;
                    height: 60px;
                    input{
                        flex: 1;
                        border: none;
                        background-color: #797979;
                        color: #fff;
                        font-size: 2rem;
                        padding-left: 10px;

                        &::-webkit-input-placeholder{
                            color: rgba(#fff, 0.5);
                        }
                    }
                    button{
                        @extend %atom-btn-int--inverted;
                        background-color: $core-color;
                        padding: 0;
                        width: 60px;
                    }
                }



                &.opened{
                    width: 100%;
                    .toggle-button{
                        background-image: url(svg-bg-uri($svg-close, #fff));
                    }
                    form{
                        width:100%;
                    }
                }
            }
        }
    
        nav#main-menu{
            display: flex;
            justify-content: flex-end;
            ul{
                display: flex;
                li{
                    a{
                        @extend %atom-btn-int;
                        .btn-text:after{
                            width: 100%;
                        }
                        height: 100px;
                        padding:0 20px;
                        .btn-text{
                            color: $grey-color-mild;
                            font-size: 2rem;
                            transition: color 0.2s ease;
                            &:after{
                                background-color: $grey-color-mild;
                                transition: background 0.2s ease;
                            }
                        }
                        &:hover, &:focus{
                            .btn-text{
                                color: $grey-color-dark;
                                &:after{
                                    background-color: $grey-color-dark;
                                }
                            }
                        }
                    }
    
                    &:last-child a{
                        padding-right: 0;
                            .btn-text{
                                color: $grey-color-dark;
                                &:after{
                                    @extend %animWidthUnderlineInfinite;
                                    background-color: $grey-color-dark;
                                }
                            }
                        &:hover, &:focus{
                            .btn-text{
                                color: $grey-color-mild;
                                &:after{
                                    width: 100%;
                                    @extend %animWidthUnderline;
                                    background-color: $core-color;
                                }
                            }
                        }
                    }
                }
            }
        }
    }


    @include RWD(tablet){
        position: relative;
        overflow: visible;
        #logo{
            position: absolute;
            padding-top: 0;
            top: calc(100% - 12px);

            h1, a{
                span:nth-child(1){
                    font-size: 2rem;
                    margin-bottom:0;
                }
                span:nth-child(2){
                    font-size: 8.1rem;
                }
            }
        }
        .header-right{
            width: 100%;
        }
    }
    @include RWD(mobile){
        flex-wrap: wrap;
        padding: 0;
        background-color: #fff;
        
        #logo{
            order: 2;
            position: static;
            margin-bottom: 15px;
            top: auto;
            padding-left: 20px;
        }
        .header-right{
            order:1;
            margin-bottom: 30px;

            .nav-alt{
                background-color: #f2f2f2;
                padding: 0 10px;
                justify-content: normal;
                height: 40px;

                .social-links a{
                    width: 35px;
                    height: 40px;
                    overflow: hidden;
                    text-indent: -9999px;
                    padding: 0;
                    background-position: 50%;
                }

                .translate-select, .nav-profil{
                    display: none;
                }

                .search-block{
                    right: 50px;
                    width: 40px;
                    
                    .toggle-button{
                        height: 40px;
                        width: 40px;
                        background-color: transparent;
                        background-image: url(svg-bg-uri($svg-search, $grey-color-light));
                    }
                    form{
                        height:40px;
                        input{
                            background-color: transparent;
                        }
                        button{
                            height: 40px;
                            width: 40px;
                        }
                    }

                    &.opened{
                        width: calc(100% - 50px);
                        background-color: #797979;
                    }
                }

            }

            nav#main-menu{
                display: none;
            }

        }
        #rwdMenuToggle{
            display: block;
            height: 40px;
            width: 50px;
            position: absolute;
            right: 0;
            top: 0;

            .picto{
                position: relative;
                height: 4px;
                width: 30px;
                display: block;
                margin: 0 auto;
                background-color: $grey-color;
                &:before, 
                &:after{
                    content: '';
                    position: absolute;
                    display: block;
                    height: 4px;
                    width: 30px;
                    background-color: $grey-color;
                }
                &:before{
                    top: -9px;
                } 
                &:after{
                    bottom: -9px;
                }
            }
        }
    }

}


%organism-menu-rwd{
    position: fixed;
    height: 100%;
    width: 100%;
    top:0;
    left: 0;
    z-index: 101;
    background-color: rgba(0,0,0, 0.85);
    
    .close{
        position: absolute;
        top: 0;
        right: 0;
        width: 50px;
        height: 40px;
        background-image: url(svg-bg-uri($svg-close, #fff));
        background-size: 30px 30px;
        background-position: 50%;
        background-repeat: no-repeat;
    }
    
    .content{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        flex-direction: column;
        align-items: left;

        & > *{
            padding: 15px;
            position: relative;
            &:after{
                content:'';
                width: 50vw;
                position: absolute;
                bottom: 0;
                left: 15px;
                height: 1px;
                background-color: #fff;
            }

            &:last-child{
                &:after{
                    content: none;
                }
            }
        }


        li{
            a{
                font-size: 3rem;
                color: #fff;
                padding: 15px 0;
                display: inline-block;
                text-decoration: none;
                text-transform: uppercase;
                text-align: center;
    
                &:after{
                    content: '';
                    display: block;
                    position: relative;
                    top: 0;
                    height: 2px;
                    width: 0;
                    background-color: #fff;
                }
    
                &:hover, &:focus{
                    color: #fff;
                    &:after{
                        @extend %animWidthUnderline;
                        width: 100%;
                    }
                }
            }
        }


        .translate-select{
            position: relative;
            & > a{
                display: block;
                height: 60px;
                padding: 17px 20px 16px 45px;
                font-size: 3rem;
                text-transform: uppercase;
                color: #fff;
                text-decoration: none;
                line-height: 2rem;
                background-position: 5px 43%;
                background-repeat: no-repeat;
                background-image: url(svg-bg-uri($svg-flag, #fff));
                background-size: 20px;
                &:after{
                    content: '▼';
                    display: inline-block;
                    margin-left: 10px;
                    position: relative;
                    top: -5px;
                    font-size: 1.6rem;
                    transition: all 0.35s ease;
                }
            }
            ul{
                display: none;
                a{
                    padding: 0 0 15px 40px;
                }
            }
            &.opened{
                & > a:after{
                    transform: rotateZ(-90deg);
                }
            }
        }


        .nav-profil{
            
            display: flex;
            flex-direction: column;

            button{
                display: inline-flex;
                padding: 17px 20px 16px 40px;
                background-image: url(svg-bg-uri($svg-people, #fff));
                background-position: 5px 53%;
                background-size: 19px;
                background-repeat: no-repeat;
                position: relative;
                height: 60px;
                
                text-transform: uppercase;
                line-height: 3rem;
                font-size: 3rem;
                color: #fff;

                &:after{
                    content: '▼';
                    display: inline-block;
                    margin-left: 10px;
                    position: relative;
                    top: -5px;
                    font-size: 1.6rem;
                    transition: all 0.35s ease;
                }
            }

            .nav-profil-dropdown{
                display: none;
                overflow: hidden;
                padding-left: 40px;
                a{
                    text-align: left;
                }
            }

            &.opened{
                button:after{
                    transform: rotateZ(-90deg);
                }
            }
            
        }


    }
}