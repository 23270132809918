$path: '../fonts/';

// MontSerrat
// Black
/*@font-face {
    font-family: 'MontSerrat';
    src: url('#{$path}montserrat-black-webfont.eot');
    src: url('#{$path}montserrat-black-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$path}montserrat-black-webfont.woff2') format('woff2'),
         url('#{$path}montserrat-black-webfont.woff') format('woff'),
         url('#{$path}montserrat-black-webfont.ttf') format('truetype'),
         url('#{$path}montserrat-black-webfont.otf') format('otf'),
         url('#{$path}montserrat-black-webfont.svg#montserratblack') format('svg');
    font-weight: 900;
    font-style: normal;
}*/