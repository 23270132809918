%organism-banner{
    .region-banner{
        background-size: cover; 
        background-position: center;
        background-repeat: no-repeat;
        height: 300px;
        position: relative;
        margin-bottom: 50px;
        @include RWD(mobile){
            height: 200px;
        }
        &:before{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.08) 24%,rgba(0,0,0,0.33) 72%,rgba(0,0,0,0.43) 100%);
            z-index: 1;
        }
        & > * {
            z-index: 2;
        }
    }
    .block-container-page_title{ // Spé Drupal
        position: relative;
        height: 100%;
    }
    h1{
        @extend %atom-main-title;
        line-height: 0.9em;
    }
}