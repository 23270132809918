.not-front.page-search-site .view-grid, .not-front .view-recherche .view-grid{
    @extend %molecule-grid-basic;  
    /* Vignettes */
    .grid-item{
        margin-bottom: 30px;
        display: flex;

        a{
            flex: 1;
            @extend %atom-vignette;

            &:hover, &:focus{
                @extend %animation-vignette;
            }
        }
        .view-full-contain{
            display: none;
        }
    }
}