.front{

    @include RWD(mobile){
        background-color: #eee;
    }


    #block_Carousel{
        @extend %container;
        .carousel-wrapper{
            display: flex;

            .carousel-texts{
                @extend %home-bloc-shadow;
                position: relative;
                top: 50px;
                width: 540px;
                height: 475px;
                padding: 50px 40px 50px 64px;
                background-color: #fcfcfc;

                .slider-title{
                    font-size: 5.7rem;
                    line-height: 68px;
                    font-weight: 500;
                    margin-bottom: 45px;
                }
                .slider-description{
                    font-size: 1.8rem;
                    line-height: 25px;
                    margin-bottom: 25px;
                }
                .read-more{
                    position: relative;
                    display: inline-block;
                    font-size: 1.6rem;
                    font-weight: 600;
                    line-height: 25px;
                    text-decoration: none;
                    color: $grey-color;
                    text-transform: uppercase;
                    &:after{
                        content: '';
                        display: block;
                        position: relative;
                        top: -2px;
                        height: 2px;
                        width: 0;
                        background-color: $grey-color;
                    }

                    &:hover, &:focus{
                        &:after{
                            @extend %animWidthUnderline;
                            width: 100%;
                        }
                    }
                }
            }

            .carousel-gallery{
                width: 660px;
                height: 485px;
                .owl-stage-outer{
                    overflow: visible !important;
                }
                #slider--full{
                    .slider--full{overflow: hidden;}

                    @include animationArrowSliderMoveIn(0, 0);
                    .owl-nav .owl-prev, 
                    .owl-nav .owl-next{
                        background-image: url(svg-bg-uri($svg-arrow-line, #fff));
                        opacity: 1;
                        background-color: transparent;
                        &:hover{
                            background-color: $core-color;
                        }
                    }
                    .owl-nav .owl-prev{
                        transform: rotate(90deg);
                    }
                    .owl-nav .owl-next{
                        transform: rotate(-90deg);
                    }

                    .owl-play{
                        display: none;
                    }

                    .owl-item{
                        height: 485px;
                    }
                    
                    .owl-item .owl-video-tn:before, 
                    .owl-item:not([data-video]) > li:before{
                        content: none;
                    }

                    .owl-dots{
                        left: auto;
                        right: 20px;
                        bottom: 15px;
                        transform: none;

                        .owl-dot{
                            background-color: transparent;
                            border: 2px solid #fff;
                            padding: 0;
                            
                            &.active{
                                span{
                                    background-color: #fff;
                                }
                            }

                            &:not(.active){
                                &:hover, &:focus{
                                    span{
                                        background-color: rgba(255, 255, 255, 0.5);
                                    }
                                }
                            }
                        }
                    }

                }
            }
        }

        @include RWD(desktop){
            .carousel-wrapper .carousel-gallery #slider--full .slider--full{
                overflow: hidden;
                width: calc(100% + 300px);
                height: 525px;

                .slider{
                    width: calc(100% - 300px);
                    height: calc(100% - 40px);
                }

                // Gestion du full
                .owl-item{
                    transition: all, 0.75s;
                    opacity: 1;
                    transform: scale(1);
                    z-index: 2;
                    &:not(.active){
                        opacity: 0.7;
                        transform: scale(0.71) translate(-100px,139px);
                        transform-origin: left;
                        z-index:1;
                    }
                }
                
            }
        }

        @include RWD(tablet){
            .carousel-wrapper{
                .carousel-texts{
                    flex-shrink: 0;
                    width: 420px;
                    height: 400px;
                    padding: 40px 20px;
                    top: 125px;
                    z-index: 2;
                    .slider-title{
                        font-size: 4.2rem;
                        line-height: 42px;
                    }
                } 
                .carousel-gallery{
                    width: calc(100% - 357px);
                    transform: translateX(-63px);
                    z-index: 1;
                    #slider--full{
                        .owl-item{
                            height: 400px;
                        }
                        
                        .owl-nav .owl-prev{
                            left: 63px;
                        }
                        &:hover, &:focus{
                            .owl-nav .owl-prev{
                                left: 63px;
                            }
                        }
                    }
                }
            }
        }

        @include RWD(mobile){
            padding: 0;
            .carousel-wrapper{
                display: block;
                padding-bottom: 160px;
                position: relative;

                .carousel-texts{
                    position: absolute;
                    top: 260px;
                    width: calc(100% - 40px);
                    left: 20px;
                    padding: 30px 22px;
                    height: 330px;

                    .slider-title{
                        font-size: 3.6rem;
                        margin-bottom: 20px;
                    }
                }
                .carousel-gallery{
                    width: 100%;
                    transform: none;

                    #slider--full .owl-dots{
                        bottom: auto;
                        top:230px;
                    }
                }
            }
        }

    }


    #block_InstantAccess{
        padding-top: 320px;
        margin-top: -175px;
        padding-bottom: 65px;
        background-color: $core-color;
        ul{
            @extend %container;
            display: flex;
            justify-content: space-around;
            li{
                margin: 0 35px;
                flex: 1;
                a{
                    text-align: center;
                    text-decoration: none;
                    .ia-picto{
                        display: flex;
                        width: 80px;
                        height: 55px;
                        margin: 0 auto 20px;
                        transition: all 0.35s ease-out;
                        svg{
                            flex: 1;
                            max-height: 100%;
                            max-width: 100%;
                            fill: #fff;
                        }
                    }
                    .ia-title{
                        font-size: 1.6rem;
                        font-weight: 600;
                        line-height: 1.9rem;
                        color: $grey-color-dark;
                        text-transform: uppercase;

                         &:after{
                            content: '';
                            display: block;
                            position: relative;
                            top: 5px;
                            height: 2px;
                            width: 0;
                            background-color: #fff;
                        }
                    
                    }

                    &:hover, &:focus{
                        .ia-picto{
                            transform: translateY(-10px);
                        }
                        .ia-title:after{
                            @extend %animWidthUnderline;
                            width: 100%;
                        }
                    }
                }
            }
        }

        
        @include RWD(desktop){
            ul li{
                opacity: 0;
            }
        }

        @include RWD(tablet){
            ul{
                width: 600px;
                flex-wrap: wrap;
                padding: 0;
                li{
                    width: 200px;
                    margin: 0;
                    flex: none;
                    a{
                        display: flex;
                        flex-direction: column;
                        margin-bottom: 65px;
                        .ia-title{
                            display: inline-block;
                            margin: 0 auto;
                        }
                    }
                }
            }
        }

        @include RWD(mobile){
            padding-top: 250px;
            margin-top: -245px;
            ul{
                width: auto;
                padding: 0 20px;
                li{
                    width: 50%;
                    a{
                        margin-bottom: 30px;
                    }
                }
            }
        }

    }


    %title_subtitle{
        .block-title{
            font-size: 4.5rem;
            letter-spacing: 0.04em;
            font-weight: 600;
            text-transform: uppercase;
        }
        .block-subtitle{
            font-size: 3.5rem;
            margin-bottom: 25px;
            text-transform: lowercase;
            color: #4e4732;
        }

        @include RWD(mobile){
            .block-title{
                font-size: 3rem;
            }
            .block-subtitle{
                font-size: 2.3rem;
                margin-bottom: 15px;
            }
        }

    }


    #block_Actualites{
        padding-bottom: 190px;
        .news-header{
            background-size: cover;
            background-position: 50% -190px;
            .news-header-wrapper{
                @extend %container;
                display: flex;
                align-items: flex-end;
                height: 460px;

                .news-header-content{
                    width: calc((100% - 40px) / 3);
                    background-color: $accent-color;
                    height: 339px;
                    padding: 75px 40px 0;

                    @extend  %title_subtitle;
                    
                    a{
                        @extend %atom-btn-int--core;
                        &:hover, &:focus{
                            background-color: $second-color;
                        }
                    }
                }
            }
        }
        .news-list{
            @extend %container;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            li{
                position: relative;
                width: calc((100% - 40px) / 3);
                margin-bottom: 20px;
                overflow: hidden;
                background-size: cover;
                background-position: 50%;
                a{
                    display: flex;
                    height:100%;
                    width:100%;
                    padding: 0 30px;
                    padding-top: calc(100% - 40px);
                    flex-direction: column;
                    justify-content: flex-end;
                    background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.7) 100%);
                    background-size: 100% 200%;
                    background-position: 0 0;
                    color: #fff;
                    text-decoration: none;
                    text-align: center;
                    transition: all 0.35s ease;

                    &:hover, &:focus{
                        background-size: 100% 100%;
                    }

                    .news-content{

                        position: absolute;
                        left: 40px;
                        bottom: 20px;
                        width: calc(100% - 80px);
                        text-shadow: 1px 2px 10px black;
                        .news-title{
                            display: block;
                            margin-bottom: 25px;
                            font-size: 2.7rem;
                            font-weight: 600;
                            letter-spacing: 0.04em;
                            text-transform: uppercase;
                            &:first-line{
                                font-weight: 400;
                            }
                        }
                        .news-chapo{
                            margin-bottom: 30px;
                            font-size: 1.6rem;
                            line-height: 2.3rem;
                            height:0;
                            transition: height 0.25s ease;
                            overflow: hidden;
                        }
                    }

                    &:hover, &:focus{
                        .news-chapo{
                            height: 70px;
                        }
                    }

                }

                &:nth-child(3n +2){
                    top: 100px;
                }
            }
        }

        @include RWD(tablet){
            padding-bottom: 85px;
            .news-header {
                background-position: 50%;
            
                .news-header-wrapper{
                    height: 460px;
                    align-items: flex-end;

                    .news-header-content{
                        width: calc(50% - 10px);
                        height: 305px;
                        padding: 40px 30px 0;
                    }
                }
            }
            .news-list{
                li{
                    width: calc(50% - 10px);
                    margin-bottom: 60px;
                    
                    &:nth-child(3n +2){
                        top: 0;
                    }
                    &:nth-child(2n){
                        top: 100px;
                    }
                    &:nth-child(n+5){
                        display: none;
                    }
                }
            }
        }

        @include RWD(mobile){
            padding-bottom: 45px;
            .news-header .news-header-wrapper{
                height: 460px;
                align-items: flex-end;

                .news-header-content{
                    width: 100%;
                    height: 200px;
                    padding: 35px 20px 0;
                }
            }
            .news-list{
                li{
                    width: 100%;
                    margin-bottom: 60px;
                    
                    &:nth-child(3n +2),
                    &:nth-child(2n){
                        top: 0;
                    }
                    &:nth-child(n+3){
                        display: none;
                    }

                    .news-chapo{
                        display: none;
                    }
                }
            }
        }
    }


    #block_Agenda{
        @extend %container;
        @extend %title_subtitle;

        margin-bottom: 160px;

        .events-header{
            position: relative;
            height: 80px;
            margin-bottom: 25px;
            a{
                @extend %atom-btn-int--inverted;
                position: absolute;
                top: 20px;
                right: 0;
                .btn-text{
                    color: #395041;
                }
            }
            .block-subtitle{
                color: $grey-color-mild;
            }
        }

        .events-categories{
            display: flex;
            height: 70px;
            justify-content: space-between;
            line-height: 70px;
            border-bottom: 1px solid #dadada;

            li{

                button{
                    font-size: 1.6rem;
                    text-transform: uppercase;
                    height: 100%;
                    margin: 0 10px;
                    color: $grey-color-mild;
                    .btn-text:after{
                        content: '';
                        display: block;
                        position: relative;
                        top: -24px;
                        height: 3px;
                        width: 0;
                        background-color: $grey-color-dark;
                    }
                    &:hover, &:focus{
                        outline: none;
                        .btn-text:after{
                            @extend %animWidthUnderline;
                            width: 100%;
                        }
                    }
                    
                    &.active{
                        font-weight: 600;
                        color: $grey-color-dark;
                        .btn-text:after{
                            width: 100%;
                        }
                    }
    
                }
                &:first-child button{
                    margin-left: 0;
                }
                &:last-child button{
                    margin-right: 0;
                }
            }
        }

        .events-list{
            display: flex;
            flex-wrap: wrap;
            li{
                padding-top: 30px;
                width: 50%;
                .event{
                    display: flex;
                    flex-wrap: wrap;
                    text-decoration: none;
                    
                    .event-visu{
                        width: 190px;
                        height: 270px;
                        img{
                            max-width: 100%;
                            max-height: 100%;
                        }
                    }
                    .event-content{
                        width: calc(100% - 190px);
                        padding: 0 20px 0 40px;
                        font-size: 1.6rem;
                        color: $grey-color;
                        
                        .event-tags{
                            height: 30px;
                            padding-left: 40px;
                            background-image: url(svg-bg-uri($svg-business, $second-color));
                            background-size: 25px;
                            background-position: 0 -4px;
                            background-repeat: no-repeat;
                            text-transform: uppercase;
                            &.empty{
                                background: none;
                            }
                        }
                        .event-date{
                            height: 65px;
                            transform: translateX(-20px);
                            font-size: 2.7rem;
                            font-weight: 600;
                            color: $grey-color-mild;
                        }
                        .event-name{
                            height: 85px;
                            font-size: 2.7rem;
                            line-height: 3.3rem;
                            font-weight: 500;
                            text-transform: uppercase;
                            color: $grey-color-dark;
                            .btn-text{
                                display: inline-block;
                                &:after{
                                    content: '';
                                    display: block;
                                    position: relative;
                                    top: 5px;
                                    height: 2px;
                                    width: 0;
                                    background-color: $grey-color-dark;
                                }
                            }
                        }
                        .event-description{
                            height: 95px;
                            color: $grey-color-light;
                            line-height: 2.3rem;
                        }
                    }

                    &:hover, &:focus{
                        .event-name .btn-text:after{
                            @extend %animWidthUnderline;
                            width: 100%;
                        }
                    }
                }
            }
        }

        @include RWD(tablet){
            .events-list li .event{
                .event-visu,
                .event-content{
                    width: 100%;
                }
                .event-content{
                    padding: 20px 20px 0;
                }
            }
        }
        @include RWD(mobile){
            .events-header{
                height: auto;
                a{
                    position: static;
                    top: 0;
                    right: auto;
                    width: calc(100% - 20px);
                    margin: 0 auto;
                }
            }
            .events-list li{
                width: 100%;
                .event .event-content{
                    .event-tags{
                        display: none;
                    }
                    .event-date{
                        font-size: 2.2rem;   
                    }
                    .event-name{
                        font-size: 2.4rem;
                    }
                }

                &:nth-child(n+3){
                    display: none;
                }
            }
        }

    }


    #block_Highlight1{
        height: 445px;
        .wrapper{
            @extend %container;
            display: flex;
            justify-content: flex-end;
            background-size: cover;
            background-position: 50%;
            
            .highlight-content{
                width: 550px;
                height: 375px;
                padding: 75px 40px;
                transform: translateY(-50px);
                background-color: $accent-color;
                @extend %home-bloc-shadow;

                @extend  %title_subtitle;
                .block-subtitle{
                    margin-bottom: 10px;

                    & + p{
                        margin-bottom: 30px;
                        font-size: 1.6rem;
                        line-height: 2.6rem;
                    }
                }

                a{
                    @extend %atom-btn-int--core;
                    &:hover, &:focus{
                        background-color: $second-color;
                    }
                }
            }
        }
        @include RWD(tablet){
            .wrapper .highlight-content{
                transform: translateY(-30px);
            }
        }
        @include RWD(mobile){
            height: auto;
            background-image: none !important;
            margin-bottom:50px;
            .wrapper .highlight-content{
                transform: none;
                width:100%;
                height:auto;
                padding: 30px 20px;

                a{
                    width: calc(100% - 40px);
                    margin: 0 20px;
                }
            }
        }
    }

    .bg-wrapper-publications-gallery{
        background-color: #eee;
    }
    .wrapper-publications-gallery{
        @extend %container;
        display: flex;
        flex-wrap: wrap;

        @include RWD(tablet){
            transform: translateY(-50px);
        }
        @include RWD(mobile){
            transform: none;
        }   

    }


    #block_Publications{
        width: 550px;
        margin-right: 50px;
        margin-bottom: 40px;
        //transform: translateY(-50px);
        padding: 75px 40px;
        background-color: #fff;
        @extend %home-bloc-shadow;

        @extend %title_subtitle;

        .block-subtitle{
            color: $grey-color;
        }

        .publications-list{
            display: flex;
            flex-wrap: wrap;
            .visu{
                width: 185px;
                margin-right: 20px;
                img{
                    max-width: 100%;
                }
            }
            ul {
                width: calc(100% - 205px);
                li{
                    a{
                        display: inline-block;
                        margin-bottom:20px;
                        color: $grey-color-light;
                        font-size: 1.6rem;
                        text-decoration: none;
                        text-transform: uppercase;
                        transition: color 0.2s ease;
                        &:before{
                            content: '>';
                            display:inline-block;
                            margin-right: 10px;
                            transition: color 0.35s ease;
                        }
                        &:hover, &:focus{
                            color: $grey-color-dark;
                            &:before{
                                color: $accent-color;
                            }
                        }
                    }
                    &:first-child{
                        a{
                            margin-bottom: 30px;
                            font-size: 2.1rem;
                            font-weight: 500;
                            color: $grey-color-mild;
                            &:before{ content: none; }
                            
                        }
                    }
                }
            }
        }

        @include RWD(tablet){
            transform: none;
            margin-bottom: 45px;
        }

        @include RWD(mobile){
            width: 100%;
            margin: 0 0 55px;
            padding: 30px 20px;
            transform: none;
            .publications-list{
                .visu{
                    width: 100%;
                    margin: 0 20px 15px;
                    text-align: center;
                }
                ul{
                    width: 100%;
                }
            }
        }
    }


    #block_Pictures{
        width: 600px;
        padding-top: 75px;

        .pictures-list{
            display: flex;
            justify-content: flex-end;
            flex-wrap: wrap;
            position: relative;

            .visu-wrapper{
                width: calc((100% - 65px) / 3);
            }
            .visu{
                
                padding-top: 100%;
                position: relative;
                z-index: 1;
                cursor: pointer;

                img{
                    position: absolute;
                    top: 0;
                    left:0;
                    max-width: 100%;
                    max-height: 100%;
                    transition: all 0.35s ease;
                }
            }

            .pictures-title{
                @extend  %title_subtitle;

                position:absolute;
                bottom: 0;
                left: 25px;
                .block-subtitle{
                    margin-bottom: 0;
                    text-transform: none;
                    color: $grey-color;
                }
                .block-title{
                    max-width: 190px;
                }
            }

        }    
        
        @include RWD(desktop){
            .pictures-list .visu:hover{
                img{
                    transform: scale(1.2);
                }
                z-index: 2;
            }
        }

        @include RWD(tablet){
            margin-left: auto;
            padding-top: 0;
        }
        @include RWD(mobile){
            margin-bottom: 40px;
            width: 100%;
            .pictures-list{
                padding-top: 95px;

                .visu-wrapper{
                    width: 50%;
                }
                .visu{
                    width: 100%;

                    &:nth-child(n + 5){
                        display: none;
                    }
                }

                .pictures-title{
                    bottom: auto;
                    top: 0;
                    left: 0;
                    width: 100%;

                    .block-subtitle{
                        margin-bottom: 10px;
                    }
                    .block-title{
                        font-size: 3.6rem;
                        max-width: 100%;
                    }
                }
            }
        }
    }

    .overlay{
        position:fixed;
        top:0;
        right:0;
        background: linear-gradient(to top, rgba(0,0,0,1) 0%, rgba(19,19,19,0.2) 100%);
        width:100%;
        height:100%;
        z-index: 101;

        .pop-up{
            position:absolute;
            top:50%;
            left:50%;
            transform:translate(-50%, -50%);
            width:calc(100% - 100px);
            height: 90vh;
            background-color: #fff;

            .close{
                position: absolute;
                top: 20px;
                right: 20px;
                display: inline-block;
                width: 32px;
                height: 32px;
                border-radius: 16px;
                opacity: 1;
                z-index: 2;
                span{
                    display: inline-block;
                    width: 30px;
                    height: 30px;
                    background: transparent url(svg-bg-uri($svg-close, #fff)) no-repeat 50% 50%;
                    background-size: 30px 30px;
                    text-indent: -9999px;
                }
            }
            #slider--cms{
                @extend %molecule-slider--cms;
                height:100%;
                .owl-item, .slider-int .owl-item{
                    height: 90vh;
                }
            }

            @include RWD(tablet){
                height:500px;

                #slider--cms{
                    .owl-item, .slider-int .owl-item{
                        height: 500px;
                    }
                }
            }
        }
    }


    #block_Highlight2{
        position:relative;
        display:flex;
        justify-content: center;
        align-items: center;
        height: 405px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50%;
        overflow: hidden;
        &:after{
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background-color: rgba($grey-color-light, 0.7);
        }

        .highlight-content{
            @extend %container;
            @extend %title_subtitle;
            z-index: 1;
            text-align: center;
            color: #fff;

            .block-title{
                padding-top: 75px;
                background-image: url(svg-bg-uri($svg-map-marker, #fff));
                background-size: 29px 42px;
                background-position: 50% 0;
                background-repeat: no-repeat;
            }
            .block-subtitle{
                color: #fff;
                margin-bottom: 40px;
            }

            a{
                @extend %atom-btn-int--inverted;
                background-color: $core-color;


                transition: all 0.5s ease-out, background-color 0.35s ease;
            }
        }
    }



    @include RWD(desktop){
        // ANIMATIONS
        [data-scroll-animation='grid-delayed']{
            .delayed-element{
                opacity: 0;
                transform: translateY(25px);
                transition: all, 0.5s;
            }
            @for $i from 1 through 6{
                .delayed-element-#{$i}{
                    transition-delay: #{($i - 1) * 0.25}s;
                }
            }
            &[data-animation-state='active']{
                .delayed-element{
                    opacity: 1 !important;
                    transform: translateY(0);
                }
            }
        }

        [data-scroll-animation='grid-line-delayed']{
            .delayed-element{
                opacity: 0;
                transform: translateY(25px);
                transition: all, 0.5s;
            }
            .delayed-element-1,
            .delayed-element-2,
            .delayed-element-3{
                transition-delay: 0s;
            }
            .delayed-element-4,
            .delayed-element-5,
            .delayed-element-6{
                transition-delay: 0.5s;
            }
            &[data-animation-state='active']{
                .delayed-element{
                    opacity: 1 !important;
                    transform: translateY(0);
                }
            }
        }

        [data-scroll-animation='grid-fade']{
            .anim-element{
                opacity: 0;
                transform: translateY(5px);
                transition: all, 0.5s;
                transition-delay: 0.5s;
            }
            &[data-animation-state='active']{
                .anim-element{
                    opacity: 1;
                    transform: translateY(0);
                }
            }
        }

        [data-scroll-animation='zoom']{
            opacity: 0;
            transform: scale(0.8);
            transition: all, 0.5s;
            transition-delay: 0.5s;
            &[data-animation-state='active']{
                opacity: 1;
                transform: scale(1);
            }
        }

        [data-scroll-animation='grid-child']{
            & > *{
                opacity: 0;
                transform: translateY(25px);
                transition: all, 0.5s;

                @for $i from 1 through 4{
                    &:nth-child(#{$i}){
                        transition-delay: #{($i - 1) * 0.25}s;
                    }
                }                
            }

            
            &[data-animation-state='active'] > *{
                opacity: 1;
                transform: translateY(0);
            }
        }

        [data-scroll-animation='dezoom']{
            opacity: 0;
            transform: translateY(-50px) scale(2);
            transition: all, 0.5s;
            transition-delay: 0.5s;
            &[data-animation-state='active']{
                opacity: 1;
                transform: translateY(-50px) scale(1);
            }
        }

        [data-scroll-animation='slideup-delayed']{
            .delayed-element{
                opacity: 0;
                transform: translateY(100px);
                transition: all 0.5s ease-out;
            }
            @for $i from 1 through 3{
                .delayed-element-#{$i}{
                    transition-delay: #{($i - 1) * 0.4}s !important;
                }
            }
            &[data-animation-state='active']{
                .delayed-element{
                    opacity: 1 !important;
                    transform: translateY(0);
                }
            }        
        }

        


    }



    // TODO - A bouger ailleurs

    // .loading{
    //     position: relative;

        
    //     &:after{
    //         content: 'Chargement ...';
    //         color: #fff;
    //         position: absolute;
    //         top:0;
    //         left: 0;
    //         width: 100%;
    //         height: 100%;
    //         background-color: rgba(0,0,0,0.2);
    //     }

    //     &.events-list{
    //         height: 590px;
    //     }
    // }

}