%atom-sliders-dot--full{
    background-color: #ffffff;
    padding: 2px;
    border-radius: 50%;
    margin: 5px 7px;
    span{
        width: 10px;
        height: 10px;
        display: block;
        border-radius: 5000px;
        transition: all, 0.5s;
    }

    &.active{
        cursor: auto;
        span{
            background-color: $core-color;
        }
    }
    &:not(.active){
        &:hover, &:focus{
            span{
                background-color: $core-color;
            }
        }
    }
}