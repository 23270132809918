%molecule-media-map{
    clear: both;
    .media-body{
        padding: 0;
    }
    .media-text{
        padding: 20px;
    }
    .media-ratio{
        margin-bottom: 0;
        position: relative;
        overflow: hidden;

        .gmap-infowindow{
            @extend %atom-map-infowindow;
            position: absolute;
            top: 0; right: 0;
            height: 100%;
            width: 400px;
            max-width: 100%;
            transform: translateX(100%);
            transition: all, 0.5s;
    
            @include RWD(mobile){
                width: 100%;
            }
    
            &.opened{
                transform: translateX(0);
            }
        }
    }
}