%molecule-slider--full{
    position: relative;

    // Activation du mode background-cover
    @extend %bones-slider-cover-ratio--full;
  
    // Animation arrow prev next fadein au hover du slider
    @include animationArrowSliderMoveIn(0, 40px); 
    
    // FULL HEIGHT => .owl-item: 100vh + le slider en position absolute, top/bottom/left/right 0 + pager en position absolute;

    // Taille et couleur des slides
    .owl-item{
        height: 555px;
        @include RWD(tablet){
            height: 500px;
        }
        @include RWD(mobile){
            height: 250px;
        }
        background-color: #d4d4d4;

        // Slide image
        &:not([data-video]) > li{
            @extend %atom-slide--full;
        }

        // Slide Video
        .owl-video-tn{
            @extend %atom-slide--full--video;
        }
    }

    // TEXTE DU SLIDER
    .slider-text{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 100;
        text-decoration: none;
        text-align: center;
        max-width: 80%;
        width: 100%;

        .slider-title{
            font-size: 4.0rem;
            color: #FFFFFF;
            font-weight: 600;
            text-transform: uppercase;
            & + .slider-description{
                margin-top: 20px;
            }
        }
        .slider-description{
            font-size: 2.0rem;
            color: #FFFFFF;
            font-weight: 400;
            text-transform: capitalize;
        }
    }

    // NEXT/PREV ARROWS
    .owl-nav{
        justify-content: center;
        display: flex;
        @extend %bones-slider-Ycentered-control--full;

        @include RWD(mobile){
            display: none;
        }

        .owl-prev{
            @extend %atom-slider-arrow--full--grey; // Flèches
            //@extend %atom-slider-arrow-paged--full--grey; // Pagination X/Y
            // @extend %animation-slider-arrow-fade;
            // left: 0;
        }
        .owl-next{ 
            @extend %atom-slider-arrow--full--grey; // Flèches
            //@extend %atom-slider-arrow-paged--full--grey; // Pagination X/Y
            // @extend %animation-slider-arrow-fade;
            // right: 0;
        } 

        &.disabled{
            display: none;
        }
    }

    // PAGERS
    .owl-dots{
        justify-content: center;
        display: flex;
        position: absolute;
        bottom: 50px;
        left: 50%;
        transform: translateX(-50%);

        .owl-dot{
            @extend %atom-sliders-dot--full;
        }
    }

    // PlayPause
    .owl-play{
        position: absolute;
        bottom: 40px;
        right: 40px;

        @include RWD(mobile){
            display: none;
        }
        content: '';
        display: inline-block;
        width: 20px;
        height: 20px;
        background-size: 20px;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url( svg-bg-uri($svg-play--full, #FFFFFF) );
        z-index: 10;

        &.pause{
            background-image: url( svg-bg-uri($svg-pause--full, #FFFFFF) );
        }
    }
    
    // SCROLLDOWN
    .scrollDown{
        z-index: 100;
        position: absolute;
        display: block;
        width: 20px;
        height: 20px;
        
        @include RWD(desktop){
            left: 50px;
            animation: updown 1s infinite alternate;
            animation-timing-function: ease-in-out; 
              
            @keyframes updown {
                0% { opacity: 0; bottom: -15px; }
                100% { opacity: 1; bottom: -25px; }
            }
        }
        @include RWD(tablet_only){
            top: 110%;
            left: 120px;
            animation: updown 1s infinite alternate;
            animation-timing-function: ease-in-out; 
              
            @keyframes updown {
                0% { opacity: 0; top: calc(110% - 5px); }
                100% { opacity: 1; top: calc(110% + 5px); }
            }
         
        }

        &:before, &:after{
            content: '';
            position: absolute;
            display: inline-block;
            width: 20px;
            height: 11px;
            background-size: 20px 11px;
            background-repeat: no-repeat;
            background-image: url( svg-bg-uri($svg-arrow-line, #FFFFFF) );
            background-position: 0 0;
            left: 0;
        }
        &:before{
            top: 0;
        }
        &:after{
            top: 8px;
        }
    }
}