%atom-main-title{
    @extend %text-h1;
    @extend %container;
    text-align: center;
}

%atom-h2-rte{
    @extend %text-h2;
    // effet souligné $core-color
    //transition: background-position .3s cubic-bezier(.215,.61,.355,1);
    background-image: linear-gradient(to bottom,$core-color 0,$core-color 100%);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: 0 .83em;
    display: inline;
    &:before, &:after{
        content: '';
        display: flex;
    }
    &:before{
        height: 60px;
    }
    &:after{
        margin-bottom: 20px;
    }
}
%atom-h3-rte{
    @extend %text-h3;
    padding-left: 22px;
    padding-top:45px;
}
%atom-h4-rte{
    @extend %text-h4; 
    margin-left: 12px;
    padding-left: 10px;
    // effet souligné $second-color
    background-image: linear-gradient(to bottom, transparent 0, transparent 12%,$second-color 12%, $second-color 90%, transparent 90%, transparent 100%);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: 0;
    display: inline;
}
%atom-h5-rte{
    @extend %text-h5;
    padding-top:25px;
}
